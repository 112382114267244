import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { MyDocument } from './export-to-pdf-preview-component';
import DatePicker from 'react-datetime';
import { truckRatesDataService } from '../services/truckRates-data-service';

export interface ExportToPdfProps {
    onSuccessClick: () => void;
    onClose: () => void;
    quoteData: any;
}

export class ExportToPdfPopup extends React.Component<ExportToPdfProps> {

    formatDate = (dateString: string): string => {
        if (!dateString) return 'N/A';
        return dateString.replace('T', ' ').split('.')[0];
    };
    state = {
        CustomerName: this.props.quoteData[0]?.Consignor || '',
        EffectiveDate: this.formatDate(this.props.quoteData[0]?.EffectiveDate || ''),
        isTextBoxVisible: false,
        footerNotes: '',
        footerValue: '',
        addressValue: '',
        isDataFetched: false,
        typingNotification: '',
        isFooterPopupVisible: false,
    };

parseNoteContent(data: string) {
    const cleanedContent = data.replace(/<\/?Text>/g, '').trim();
    const parts = cleanedContent.split('\n');
    const cleanedParts: string[] = [];
    
    parts.map((part, index) => {
        const trimmedPart = part.trim();
        if (trimmedPart) {
            cleanedParts.push(trimmedPart);
        }
        
    });
    return cleanedParts.join('\n');
}

handleViewFooter = () => {
    this.setState({ isFooterPopupVisible: true });
};
    fetchValues = async () => {
        
            const result = await truckRatesDataService.getTruckRatesPdfValues();
            const tRPdfAddress = result.find((x) => x.Code === 'TruckRatesPDFAddre')?.Value || '';
            const tRPdfNotes = result.find((x) => x.Code === 'TruckRatesPDFNotes')?.Value || '';
            this.setState({
                footerNotes: this.parseNoteContent(tRPdfNotes),
                footerValue: this.parseNoteContent(tRPdfNotes),
                addressValue: tRPdfAddress,
                isDataFetched: true,
            });
        
    };

    handleCustomerNameChange = (e) => {
        this.setState({ CustomerName: e.target.value });
    };

    handleEffectiveDateChange = (date) => {
        this.setState({ EffectiveDate: date.format('YYYY-MM-DD HH:mm:ss') });
    };

    handleFooterNotesChange = (e) => {
    const input = e.target.value;
    if (this.state.footerValue.length > 1200) {
        this.setState({ typingNotification: 'Character limit exceeded' });
        return;
    }
    this.setState({
        footerValue: input,
        typingNotification: '',
    });
        this.setState({
            footerValue: input,
            typingNotification: '',
        });  
    };
    

    handleFooterSave = () => {
        this.setState({
            footerNotes: this.state.footerValue,
            isFooterPopupVisible: false,
        });
    };

    handleFooterCancel = () => {
        this.setState({
            footerValue: this.state.footerNotes,
            isFooterPopupVisible: false,
            typingNotification: '',
        });
    };

    handleSubmit = async () => {
        const { CustomerName, EffectiveDate, addressValue, footerNotes } = this.state;
        const { onClose, quoteData, onSuccessClick } = this.props;

        const pdfBlob = await require('@react-pdf/renderer').pdf(
            <MyDocument
                quoteData={quoteData}
                values={{
                    customerName: CustomerName,
                    effectiveDate: EffectiveDate,
                    address: addressValue,
                    notes: footerNotes,
                }}
            />
        ).toBlob();

        const pdfURL = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfURL;
        link.download = 'TruckRates.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(pdfURL);

        onSuccessClick();
        onClose();
    };

    handleCancel = () => {
        const { onClose, onSuccessClick } = this.props;
        onSuccessClick();
        onClose();
    }

    render() {
        const { CustomerName, EffectiveDate, isFooterPopupVisible, footerValue, isDataFetched, typingNotification } = this.state;

        if (!isDataFetched) {
            this.fetchValues();
        }

        const labelStyle = {
            fontSize: '13px',
            fontWeight: 700,
        };
        const inputStyle = {
            fontSize: '13px',
        };
        const textBoxStyle: React.CSSProperties = {
            marginTop: '20px',
            height: '200px',
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            fontSize: '13px',
            resize: 'none',
            width: '100%',
        };
        

        return (
            <div className="exporttopdfpopup">
                <div className="popup-body">
                    {/* Customer Name */}
                    <Row className="form-group align-items-center">
                        <Col xs={4}>
                            <label htmlFor="customerName" style={labelStyle}>
                                Customer Name:
                            </label>
                        </Col>
                        <Col xs={8}>
                            <input
                                id="customerName"
                                type="text"
                                className="form-control"
                                placeholder="Enter Customer Name"
                                value={CustomerName}
                                onChange={this.handleCustomerNameChange}
                                style={inputStyle}
                            />
                        </Col>
                    </Row>

                    {/* Effective Date */}
                    <Row className="form-group align-items-center">
                        <Col xs={4}>
                            <label htmlFor="effectiveDate" style={labelStyle}>
                                Effective Date:
                            </label>
                        </Col>
                        <Col xs={8}>
                            <DatePicker
                                inputProps={{
                                    id: 'effectiveDate',
                                    className: 'form-control',
                                    placeholder: 'Select Effective Date',
                                    style: inputStyle,
                                }}
                                value={EffectiveDate}
                                dateFormat="YYYY-MM-DD"
                                timeFormat="HH:mm:ss"
                                onChange={this.handleEffectiveDateChange}
                                closeOnSelect={false}
                            />
                        </Col>
                    </Row>

                    {/* Toggle Footer Section */}
                    <Row>
                        <Col className="text-center">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleViewFooter}
                            >
                                View Footer Notes
                            </CustomButton>
                        </Col>
                    </Row>

                    {/* Footer Notes Section */}
                    {isFooterPopupVisible && (
                        <Modal show={isFooterPopupVisible} onHide={this.handleFooterCancel} centered dialogClassName="custom-modal">
                        <Modal.Body>
                            <textarea
                                className="form-control"
                                rows={10}
                                value={footerValue}
                                onChange={this.handleFooterNotesChange}
                                placeholder="Enter footer notes (max 1200 characters)"
                                style={{ fontSize: '14px', width: '100%' }}
                            />
                            {/* <small
                     style={{
                         float: 'right',
                         marginTop: '5px',
                         color: typingNotification ? 'red' : 'black',
                     }}
                 >
                     {typingNotification || `${footerValue.length}/1200`}
                 </small> */}
                        </Modal.Body>
                        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                        <Row>
                                 <Col xs={6} className="text-center">
                                     <CustomButton
                                         type={CustomButtonType.Ok}
                                         onClick={this.handleFooterSave}
                                         disabled={!footerValue}
                                     >
                                         Save
                                     </CustomButton>
                                 </Col>
                                 <Col xs={6}>
                                     <CustomButton
                                         type={CustomButtonType.Cancel}
                                         onClick={this.handleFooterCancel}
                                     >
                                         Cancel
                                     </CustomButton>
                                 </Col>
                             </Row>
                        </div>
                    </Modal>
                    )}
                </div>

                {/* Footer Buttons */}
                <div className="popup-footer">
                    <Row>
                        <Col xs={6} className="text-right">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleSubmit}
                                disabled={!CustomerName.trim() || !EffectiveDate.trim()}
                            >
                                OK
                            </CustomButton>
                        </Col>
                        <Col xs={6} className="text-left">
                            <CustomButton
                                type={CustomButtonType.Cancel}
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </CustomButton>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
