import React, { Component } from 'react';
import { GridOptions, CellEditingStartedEvent, CellEditingStoppedEvent, RangeSelectionChangedEvent, FilterChangedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { dataStore } from 'features/common/stores';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { APPROVE, EXPORTPDF, IMPORT, SENDTORA, operationsSelectOptions, rowDetails } from '../constants/constant';
import { TruckRatesDataStore } from '../stores/truckRates-data-store';
import { agGridService } from 'features/common/services/ag-grid-service';
import { truckRatesColDef, truckRatesMngrColDef } from '../domains/truckRates-col-def';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { TruckRatesDataService, truckRatesDataService } from '../services/truckRates-data-service';
import { TicketApproverCommentPopup } from './ticket-approver-comment-popup';
import ReactSelect from 'react-select';
import { PathDataTypeMap } from 'shared/static/data';
import history from 'shared/services/history';
import { DataStore } from 'features/common/stores/data-store';
import Select from 'react-select';
import { accountStore } from 'features/account/stores';
import { uiService } from 'shared/services/ui-service';
import { truckRatesDataStore } from '../stores';
import _ from 'lodash';
import { TruckRatesStatus } from '../operations_constants';

interface TruckRatesMappingProps {
    truckRatesDataStore: TruckRatesDataStore;
}

@inject('truckRatesDataStore')
@observer
export class TruckRatesComponent extends Component<TruckRatesMappingProps> {
    fileInputRef = React.createRef<HTMLInputElement>();
    constructor(props) {
        super(props);
        dataStore.setModuleName('TruckRates');
    }

    componentDidMount() {
        this.props.truckRatesDataStore.init();
    }

    componentWillUnmount() {
        // this.props.dataStore.reset();
    }


    render(): React.ReactNode {
        const {
            truckRatesDataStore: { isDisabled, dataToBeExported, truckRatesData, dailyBackupReport, leaseOperatorName,consigneeName, isApproveEnabled, isApproveRoleDisabled, isSendToRADisabled }
            // isDisabled, isApproverPopUpVisible }
        } = this.props;
        

        return (
            <Container fluid>
                <>
                    {/* <Row className="mt-3">
                        <Col>
                            <form className="form-inline">
                                <label className="datamanagement__label">Select Type</label>
                                <div style={{ width: '210px' }}>
                                    <ReactSelect
                                        // values={'Truck Rates'}
                                        placeholder="Truck Rates"
                                        options={operationsSelectOptions}
                                        onChange={this.handleDataTypeChange}
                                        isMulti={false}
                                    />
                                </div>
                            </form></Col>
                    </Row> */}
                    <Row className="mt-3" style={{ paddingLeft: '20px' }}>
                        <div>
                            <div className="form-inline padding-bottom">
                                {/* Quote ID:{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        // value={}
                                        placeholder = 'Select QuoteID'
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        // onChange={this.handleTicketType}
                                        options={quoteIDArr}
                                        isMulti={true}
                                    /> </div> */}
                                Consignor :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={truckRatesDataStore.selectedConsignor}
                                        placeholder='Select Consignor'
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        onChange={this.handleConsignorChange}
                                        options={leaseOperatorName}
                                        isMulti={true}
                                    /> </div>
                                Consignee :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={truckRatesDataStore.selectedConsignee}
                                        placeholder='Select Consignee'
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        onChange={this.handleConsigneeChange}
                                        options={consigneeName}
                                        isMulti={true}
                                    /> </div>
                                Status :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={truckRatesDataStore.selectedStatus}
                                        placeholder='Select Status'
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        onChange={this.handleStatusChange}
                                        options={TruckRatesStatus}
                                        isMulti={true}
                                    /> </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div className="master-data__action">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        {dataStore.checkOperationAccess('Save') && (
                                            <>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleApprove}
                                                    disabled={isApproveEnabled() || isApproveRoleDisabled()}
                                                >
                                                    {APPROVE}
                                                </CustomButton>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleImport}
                                                    disabled={isDisabled()}
                                                >
                                                    {IMPORT}
                                                </CustomButton>
                                            </>
                                        )}
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleExport}
                                            disabled={dataToBeExported.length === 0 || isDisabled()}
                                        >
                                            {EXPORTPDF}
                                        </CustomButton>
                                        <ClearButtonComponent
                                            handleRefresh={this.handleRefresh}
                                            isExportCSV={false}
                                        // disabled={isDisabled()}
                                        ></ClearButtonComponent>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleSyncRA}
                                            disabled={isSendToRADisabled()}
                                        >
                                            {SENDTORA}
                                        </CustomButton>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleSelectAll}
                                        >
                                            {this.props.truckRatesDataStore.getButtonText()}
                                        </CustomButton>
                                    </Col>
                                </Row>
                            </div>
                            {/* <ReactDayPickerInput _dataStore={this.props.dataStore} showFirstDay={true}></ReactDayPickerInput> */}
                            <AgGridComponent gridConfig={this.getGridConfig()} />
                        </Col>
                    </Row>
                </>
            </Container>
            // <></>
        );
    }

    componentDidUpdate() {
        const filters: any = localStorageService.get('TruckRates_filter');
        if (
            filters &&
            !_.isEqual(JSON.stringify(filters), JSON.stringify(agGridService.getAllFilters())) &&
            Object.keys(filters).length > 0
        ) {
            agGridService.setAllFilters(filters);
        }
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
    };

    onCellClicked = async event => {
        if (event.column.getColId() == 'QuoteID') {
            this.props.truckRatesDataStore.getQuoteIDDetails(event.data);
        }
        if (event.column.getColId() == 'LinkToPDF') {
            console.log(event.data.Version, !event.data.Version)
            this.props.truckRatesDataStore.uploadDownloadPDF(event.data.QuoteID, !event.data.Version || event.data.Version === null ? 0 : event.data.Version);
        }
    };

    handleSelectAll = () => {
        const csvData: any = agGridService.getNodes();
        const data: any = [];
        csvData.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        this.props.truckRatesDataStore.handleSelectAllData(data);
        csvData.refreshCells({ force: true });
    };

    handleConsignorChange = (option) => {
        const { truckRatesDataStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            this.setState((truckRatesDataStore.selectedConsignor = option));
        } else {
            this.setState((truckRatesDataStore.selectedConsignor = []));
        }
        this.props.truckRatesDataStore.statusFilterrecords();
        if(truckRatesDataStore.selectedConsignee.length === 0) {
            truckRatesDataStore.consigneeName = [];
            truckRatesDataStore.consignee = Array.from(new Set(truckRatesDataStore.truckRatesData.map((item: any) => item.Consignee)));
            truckRatesDataStore.consignee.forEach(name => {
                truckRatesDataStore.consigneeName.push({ value: name, label: name });
        });}
    }

    handleConsigneeChange = (option) => {
        const { truckRatesDataStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            this.setState((truckRatesDataStore.selectedConsignee = option));
        } else {
            this.setState((truckRatesDataStore.selectedConsignee = []));
        }
        this.props.truckRatesDataStore.statusFilterrecords();
        if(truckRatesDataStore.selectedConsignor.length === 0) {
            truckRatesDataStore.leaseOperatorName = [];
            truckRatesDataStore.leaseoperator = Array.from(new Set(truckRatesDataStore.truckRatesData.map((item: any) => item.Consignor)));
            truckRatesDataStore.leaseoperator.forEach(name => {
                truckRatesDataStore.leaseOperatorName.push({ value: name, label: name });
        });}
    }

    handleStatusChange = (option) => {
        const { truckRatesDataStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            this.setState((truckRatesDataStore.selectedStatus = option));
        } else {
            this.setState((truckRatesDataStore.selectedStatus = []));
        }
        this.props.truckRatesDataStore.statusFilterrecords()
    }

    handleApprove = (): void => {
        this.props.truckRatesDataStore.approveTruckRates();
    };

    handleImport = (): void => {
        const confirmService = new ConfirmService();
        confirmService.showExcelPopup(async consignor => {
            let userID = accountStore.getUserID();
            if (!userID || userID === 0) {
                await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                    userID = accountStore.getUserID();
                });
                if (!userID || userID === 0) {
                    return;
                }
            }
            consignor.forEach(data => {
                data.CreatedBy = userID;
                data.EffectiveDate = data.EffectiveDate ? this.getJsDateFromExcel(data.EffectiveDate) : null
                data.CalculationDate = data.CalculationDate ? this.getJsDateFromExcel(data.CalculationDate) : null
                data.ExpiryDate = data.ExpiryDate ? this.getJsDateFromExcel(data.ExpiryDate) : null
            })
            const reqbody = {
                ReportJson: consignor
            };

            console.log(reqbody);
            confirmService.showTruckRatesImportPopup(() => {
            }, false)
            const response = await truckRatesDataService.uploadTruckExcel(reqbody);
            confirmService.showTruckRatesImportPopup(() => {
            }, true)

            this.props.truckRatesDataStore.loadViewModel()
        })
    };

    getJsDateFromExcel(excelDate) {
        const SECONDS_IN_DAY = 24 * 60 * 60;
        const MISSING_LEAP_YEAR_DAY = SECONDS_IN_DAY * 1000;
        const MAGIC_NUMBER_OF_DAYS = (25567 + 2);
        if (!Number(excelDate)) {
            alert('wrong input format')
        }

        const delta = excelDate - MAGIC_NUMBER_OF_DAYS;
        const parsed = delta * MISSING_LEAP_YEAR_DAY;
        const date = new Date(parsed)

        return date.toISOString()
    }

    handleRefresh = (): void => {
        this.props.truckRatesDataStore.loadViewModel();
    }
    handleSyncRA = () => {
        this.props.truckRatesDataStore.sendToRATruckRates();
    }

    handleExport = () => {
        this.props.truckRatesDataStore.exportPDF();
    }



    private getGridConfig(): GridOptions {
        const rowData = this.props.truckRatesDataStore.truckRatesData;
        const columnDefs = this.props.truckRatesDataStore.isDisabled() ? truckRatesMngrColDef : truckRatesColDef; // getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const onCellClicked = this.onCellClicked;
        const onRangeSelectionChanged = this.handlerRangeSelectionChanged;
        const onFilterChanged = this.onFilterChanged
        const hazardAssessmentHistoryMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped,
            onCellClicked,
            onRangeSelectionChanged,
            onFilterChanged
        };

        return agGridService.getGridConfig(hazardAssessmentHistoryMaintenanceGridParams);
    }
    handleDataTypeChange = option => {
        const selectedPath = PathDataTypeMap.filter(x => x.dataTypeValue.toLowerCase() === option.value.toLowerCase());
        if (selectedPath[0]) {
            history.push(selectedPath[0].path);
        } else {
            history.push('/operations');
        }
        dataStore.setSelectedDataType(option);
    };

    handlerRangeSelectionChanged = (e: RangeSelectionChangedEvent): void => {
        const csvData: any = this.props.truckRatesDataStore.agGridService.getNodes();
        const checkArray: any = [];

        if (e.started === false && e.finished === true) {
            var cellRanges: any = e.api.getCellRanges();
            var startRowIndex = cellRanges[0].startRow.rowIndex;
            var endRowIndex = cellRanges[0].endRow.rowIndex;

            csvData.rowModel.rowsToDisplay.forEach((x, index) => {
                if (index >= startRowIndex && index <= endRowIndex) {
                    if (!(x.data.IsOriginError || x.data.IsDestinationError) || x.data.QuoteStatus === 'Econ') {
                    this.props.truckRatesDataStore.handleCheckboxClick(x.data, false, "IsSelected");
                    x.setDataValue('IsSelected', true);
                    checkArray.push('true');
                    }
                } else {
                    checkArray.push(x.data['IsSelected'] ? 'true' : 'false');
                }
            });

            csvData.refreshCells();

        }
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        localStorageService.set('TruckRates_filter', agGridService.getAllFilters());
        if (this.props.truckRatesDataStore.clearSelection == true) {
            this.setState((truckRatesDataStore.selectedConsignee = []))
            this.setState((truckRatesDataStore.selectedConsignor = []))
            this.setState((truckRatesDataStore.selectedStatus = []))
            this.props.truckRatesDataStore.clearSelection = false;
            this.props.truckRatesDataStore.consigneeName = this.props.truckRatesDataStore.backupConsigneeDropdown;
            this.props.truckRatesDataStore.leaseOperatorName = this.props.truckRatesDataStore.backupConsignorDropdown;
            this.props.truckRatesDataStore.statusFilterrecords();
        }
    }
}
