import React from 'react';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { Row, Col } from 'react-bootstrap'; // Importing Row and Col from react-bootstrap
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
import { truckRatesDataStore } from '../stores';
import { accountStore } from 'features/account/stores';
import { dataStore } from 'features/common/stores';

export interface QuoteIDProps {
  onClose: () => void;
  rowDetails:any;
  onLinkClick: () => void;
}

interface QuoteIDState {
  checkboxState: Record<string, boolean>;
}

export class QuoteIDPopup extends React.Component<QuoteIDProps, QuoteIDState> {

  state: QuoteIDState = {
    checkboxState: {
      // approve: false,
      // rateSheet: false,
    },
  };
  module = dataStore.getCurrentModuleName()

  handleCheckboxChange = (key: string) => {
    this.setState((prevState) => ({
      checkboxState: {
        ...prevState.checkboxState,
        [key]: !prevState.checkboxState[key],
      },
    }));
  };

  render() {
    const { onClose,onLinkClick, rowDetails } = this.props;
    const { checkboxState } = this.state;
    const entries: any = Object.entries(rowDetails);
    const chunks: any = [];
    for (let i = 0; i < entries.length; i += 5) {
      chunks.push(entries.slice(i, i + 5));
    }
    console.log(chunks)
    return (
      <div className="quoteidpopup">
        {/* <Row style={{ backgroundColor: '#d3d3d3', fontWeight: 'bold', padding: '10px 0' }}>
          <Col xs={6} style={{ paddingLeft: '10px', borderRight: '1px solid #ddd' }}> Column Name </Col>
          <Col xs={6} style={{ paddingLeft: '10px' }}> Value </Col>
        </Row> */}
        <Row style={{marginBottom: '10px'}}>
          <h4 style={{paddingLeft: '16px'}}>Quote Details:</h4>
          <Col style={{textAlign: 'right', font:'Bold'}}> <h4 onClick={onClose}> X </h4> </Col>
        </Row>
        <div>
        {rowDetails ? (
          
          <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          {/* {chunks.map((row, rowIndex) => ( */}
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* {row.map(([key, value], cellIndex) => ( */}
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Quote ID:</strong> {rowDetails?.QuoteID}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Consignor:</strong> {rowDetails?.Consignor}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Consignor LSD:</strong> {rowDetails?.ConsignorLSD}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Consignee:</strong> {rowDetails?.Consignee}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Consignee LSD:</strong> {rowDetails?.ConsigneeLSD}
                </div>
              {/* ))} */}
            </div>
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* {row.map(([key, value], cellIndex) => ( */}
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Transport Type:</strong> {rowDetails?.TransportType}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Product:</strong> {rowDetails?.Product}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Lease Operator Rate:</strong> {rowDetails?.LeaseOperatorRate}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  { !accountStore.userRoles.includes('ptstruckingmanager') &&
                  <div>
                  <strong>Producer Rate:</strong> {rowDetails?.ProducerRate}
                  </div> }
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  
                  { !accountStore.userRoles.includes('ptstruckingmanager') &&
                  <div>
                  <strong>Margin (%):</strong> {rowDetails?.Margin}
                  </div> }
                </div>
              {/* ))} */}
            </div>
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* {row.map(([key, value], cellIndex) => ( */}
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Density:</strong> {rowDetails?.Density}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Load Size:</strong> {rowDetails?.LoadSize}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>One way Gravel Miles:</strong> {rowDetails?.GravelMiles}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>One Way Paved Miles:</strong> {rowDetails?.PavedMiles}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Total Miles:</strong> {rowDetails?.TotalMiles}
                </div>
              {/* ))} */}
            </div>
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* {row.map(([key, value], cellIndex) => ( */}
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Drive Time:</strong> {rowDetails?.DriveTime}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Pump Time:</strong> {rowDetails?.PumpTime}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Extra Time:</strong> {rowDetails?.ExtraTime}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Total Adjusted Time:</strong> {rowDetails?.AdjustedTime}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                    maxHeight: '100px',
                    overflowY: 'auto'
                  }}
                >
                  <strong>Comments:</strong> {rowDetails?.Comments}
                </div>
              {/* ))} */}
            </div>
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* {row.map(([key, value], cellIndex) => ( */}
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Quote Status:</strong> {rowDetails?.QuoteStatus}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Status:</strong>  {rowDetails?.ExpiryDate ? moment()
        .toISOString() > rowDetails?.ExpiryDate ? 'Expired' : 'Active' : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Loading District:</strong> {rowDetails?.LoadingDistrict}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Created Date:</strong> {rowDetails?.ImportDate ? dateUtils.getFormattedMSTDateTime(moment.utc(rowDetails?.ImportDate)) : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Created By:</strong> {rowDetails?.ImportByName}
                </div>
              {/* ))} */}
            </div>
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* {row.map(([key, value], cellIndex) => ( */}
                
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Approved Date:</strong> {rowDetails?.ApprovedDate ? dateUtils.getFormattedMSTDateTime(moment.utc(rowDetails?.ApprovedDate)) : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Approved By:</strong> {rowDetails?.ApprovedByName}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                  
                >
                  <strong> Link To PDF:</strong>  { this.module === 'TruckRatesReport' && rowDetails?.Version  &&<u style={{color: 'blue'}} onClick={() => {onLinkClick(); onClose()}}>Download</u>}
                  { this.module === 'TruckRates' && rowDetails?.Version  &&<u style={{color: 'blue'}} onClick={() => {onLinkClick()}}>Link</u>}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {/* <strong>Created Date:</strong> {rowDetails?.ImportDate ? dateUtils.getFormattedMSTDateTime(moment.utc(rowDetails?.ImportDate)) : ''} */}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {/* <strong>Created By:</strong> {rowDetails?.ImportByName} */}
                </div>
              {/* ))} */}
            </div>
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* {row.map(([key, value], cellIndex) => ( */}
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Calculation Date:</strong> {rowDetails?.CalculationDate ? dateUtils.getFormattedMSTDateTime(rowDetails?.CalculationDate) : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Effective Date:</strong> {rowDetails?.EffectiveDate ? dateUtils.getFormattedMSTDateTime(rowDetails?.EffectiveDate) : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Expiry Date:</strong> {rowDetails?.ExpiryDate ? dateUtils.getFormattedMSTDateTime(rowDetails?.ExpiryDate) : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {/* // <strong>Approved By:</strong> {rowDetails?.ApprovedBy} */}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                </div>
            </div>
            <div
              // key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Uploaded Date:</strong> {rowDetails?.ImportDate ? dateUtils.getFormattedMSTDateTime(moment.utc(rowDetails?.ImportDate)) : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Uploaded By:</strong> {rowDetails?.ImportByName}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <strong>Last Synced To RA Date:</strong> {rowDetails?.SendToRADate ? dateUtils.getFormattedMSTDateTime(moment.utc(rowDetails?.SendToRADate)) : ''}
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                </div>
                <div
                  // key={cellIndex}
                  style={{
                    flex: "1",
                    fontSize: '14px',
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                </div>
              {/* ))} */}
            </div>
          {/* ))} */}
        </div>
        ) : (
          <p>No details available</p>
        )}
        </div>
      </div>
    );
  }
}

