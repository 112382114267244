import { BaseConfigValue, ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';

export class TruckRatesDataService {
    constructor(private restApi: RestApiService) {}
    async getTruckRatesData() {
        return this.restApi.postWithToken<[]>(Config.getTruckRatesData);
    }

    async uploadTruckExcel(reqBody){
        const result = this.restApi.postWithToken<[]>(Config.uploadTruckRateExcel,reqBody);
        return result;
    }
    async uploadTruckRatesPdf(reqBody) {
        const { data } = await this.restApi.post<any>(Config.UploadTruckRatesPDF, reqBody, {});
        return data;
    }

    async downloadTruckRatesPDF(reqBody: {} | undefined) {
        const { data } = await this.restApi.post<any>(Config.DownloadTruckRatesPDF, reqBody, {});
        return data;
    } 

    async updateTruckApproval(reqBody) {
        const { data } = await this.restApi.post<any>(Config.updateTruckRateApproval, reqBody, {});
        return data;
    }

    async getTruckRatesPdfValues() {
        const { data } = await this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: 'SIGNUP_EMAIL' });
        return data['Data'];
    }

    async sendToRA(reqbody) {
        const { data } = await this.restApi.postWithToken<any>(Config.sendToRA,reqbody);
        return data;
    }
}

export const truckRatesDataService = new TruckRatesDataService(restApiService);
