import { AgGridService } from "features/common/services/ag-grid-service";
import { TruckRatesDataService } from "../services/truckRates-data-service";
import { uiService, UiService } from "shared/services/ui-service";
import { AccountStore } from "features/account/stores/account-store";
import Catch from 'shared/decorators/catch-decorator';
import Loader from 'shared/decorators/loader-decorator';
import { action, observable } from "mobx";
import { errorHandler } from 'shared/handlers/error-handler';
import { ConfirmService } from "shared/components/confirm/confirm-service";

export class TruckRatesDataStore {

    // @observable selectedMonth: string = 'March';
    @observable userID: number = 0;
    @observable userName: string = '';
    @observable dataToBeExported: any = [];
    pdf64String: string = '';
    dataToBeDeleted: any = [];
    @observable truckRatesData: any = [];
    @observable dailyBackupReport: any = [];
    @observable selectedConsignor: any = []
    @observable selectedConsignee: any = []
    @observable selectedStatus: any = []
    clearSelection: boolean = false;
    selectButtonText: string = 'Select All';
    leaseOperatorName: any[] = [];
    consigneeName: any[] = [];
    leaseoperator: any[] = [];
    consignee: any[] = [];
    backupConsignorDropdown: any;
    backupConsigneeDropdown: any;

    constructor(
        private _service: TruckRatesDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) { }

    @Loader
    @Catch(() => errorHandler('QuoteID details cannot be shown'))
    @action
    async getQuoteIDDetails(rowDetails): Promise<void> {
        const confirmService = new ConfirmService();
        confirmService.showQuoteIDPopup(() => {
            this.uploadDownloadPDF(rowDetails.QuoteID, !rowDetails.Version || rowDetails.Version === null ? 0 : rowDetails.Version);
        }, (rowDetails));
    }
    init(): void {
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }


    @Loader
    @Catch(() => errorHandler('TruckRates data could not be loaded. Please try again later.'))
    @action
    async loadViewModel() {
        const result: any = await this._service.getTruckRatesData();
        this.truckRatesData = result.data['Data'];
        this.truckRatesData.forEach(data => {
            data.IsSelected = false
        })
        this.dataToBeExported = [];
        this.leaseOperatorName = [];
        this.consigneeName = [];
        this.leaseoperator = Array.from(new Set(this.truckRatesData.map((item: any) => item.Consignor)));
        this.consignee = Array.from(new Set(this.truckRatesData.map((item: any) => item.Consignee)));
        this.leaseoperator.forEach(name => {
            this.leaseOperatorName.push({ value: name, label: name });
        });
        this.backupConsignorDropdown = this.leaseOperatorName;
        this.consignee.forEach(name => {
            this.consigneeName.push({ value: name, label: name });
        });
        this.backupConsigneeDropdown = this.consigneeName;
        this.setBackupReport(this.truckRatesData);
        this.statusFilterrecords();
    }

    @action
    setBackupReport(reports: []): void {
        this.dailyBackupReport = reports;
    }

    statusFilterrecords() {
        let reports: any = [];
        const selectedConsignorName = Array.from(new Set(this.selectedConsignor.map((item: any) => item.value)));
        const selectedConsigneeName = Array.from(new Set(this.selectedConsignee.map((item: any) => item.value)));
        const selectedStatus = Array.from(new Set(this.selectedStatus.map((item: any) => item.value)));

        if (selectedConsignorName.length > 0 && selectedConsigneeName.length > 0 && selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus) && selectedConsignorName.includes(element.Consignor) && selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedConsignorName.length > 0 && selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus) && selectedConsignorName.includes(element.Consignor);
            });
        }
        else if (selectedConsigneeName.length > 0 && selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus) && selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedConsignorName.length > 0 && selectedConsigneeName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedConsignorName.includes(element.Consignor) && selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedConsignorName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedConsignorName.includes(element.Consignor);
            });
        }
        else if (selectedConsigneeName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus);
            });
        }
        else {
            reports = this.dailyBackupReport
        }
        this.truckRatesData = reports;
    }
    @Loader
    @Catch(() => errorHandler('File could not be uploaded. Please try again later.'))
    @action
    async uploadDownloadPDF(currentQuoteID, version): Promise<void> {
        const confirmService = new ConfirmService();
        confirmService.showLinkToPDFPopup(async (parameterString: string) => {
            if (parameterString !== currentQuoteID) {
                const base64String = parameterString;
                const reqBody =
                {
                    QuoteID: currentQuoteID,
                    Version: version + 1,
                    ModifiedBy: this.userID,
                    PDFString: base64String,
                };
                uiService.loaderService.showLoader();
                try {
                    const result: any = await this._service.uploadTruckRatesPdf(reqBody);

                    if (result?.StatusCode === 200) {
                        this.uiService.loaderService.hideLoader();
                        this.uiService.toastService.success('File uploaded successfully.');
                        this.loadViewModel();
                    } else {
                        this.uiService.loaderService.hideLoader();
                        this.uiService.toastService.error('Failed to upload the file.');
                    }
                } catch (error) {
                    this.uiService.loaderService.hideLoader();
                    errorHandler('An error occurred while uploading the file. Please try again later.');
                }
            }
            else {
                const quoteID = parameterString;
                await this.downloadPDF(quoteID, version);
                this.loadViewModel();
            }
        }, currentQuoteID, version);
    }

    base64ToBlob = (base64: string, mimeType: string): Blob => {
        const byteCharacters = atob(base64);
        const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    };
    @action
    handleCheckboxClick(quote, value: any, colName: string): void {
        switch (colName) {
            case 'IsSelected':
                quote.IsSelected = !value;
                break;
            default:
                break;
        }
        if (quote.IsSelected) {
            this.dataToBeExported.push(quote);
        }
        else {
            this.dataToBeExported = this.dataToBeExported.filter(a => a.QuoteID !== quote.QuoteID);
        }
    };

    @Catch(() => errorHandler('File could not be downloaded. Please try again later.'))
    @action
    async downloadPDF(currentQuoteID, version): Promise<void> {
        uiService.loaderService.showLoader();
        try {
            const reqBody = {
                QuoteID: currentQuoteID,
            };
            const response = await this._service.downloadTruckRatesPDF(reqBody);
            if (response) {
                const pdfBlob = this.base64ToBlob(response.Data.PDFUrl, 'application/pdf');
                const pdfURL = URL.createObjectURL(pdfBlob);
                // const newTab = window.open(pdfURL, '_blank');
                const link = document.createElement('a');
                link.href = pdfURL;
                link.download = `${currentQuoteID}_V${version}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert('Failed to download the PDF.');
            }
            uiService.loaderService.hideLoader();
        } catch (error) {
            uiService.loaderService.hideLoader();
            alert('An error occurred while downloading the PDF.');
        }

    };

    @Loader
    @Catch(() => errorHandler('File could not be exported. Please try again later.'))
    @action
    async exportPDF() {
        const confirmService = new ConfirmService();
        confirmService.showExportToPdfPopup(async () => {

            this.dataToBeExported.forEach((quote) => {
                const value = quote.IsSelected;
                this.handleCheckboxClick(quote, value, 'IsSelected');
            });
            this.dataToBeExported = [];
            this.loadViewModel();
        }, this.dataToBeExported);
    };

    isDisabled = () => {
        let access = false;
        const currentUserRoles = this.accountStore.userRoles;
        if (currentUserRoles.includes('ptstruckingmanager')) {
            access = true;
        }
        return access;
    }

    isSendToRADisabled = () => {
        let access = false;
        const currentUserRoles = this.accountStore.userRoles;
        if (!currentUserRoles.includes('ptstruckrateadmin') && !currentUserRoles.includes('ptsdispatcher') && !currentUserRoles.includes('ptstruckratereadonly')) {
            access = true;
        } 
        return access;
    }

    @action
    isApproveEnabled = () => {
        const csvData: any = this.agGridService.getNodes();
        let data: any = [];
        const records = csvData?.rowModel.rowsToDisplay ? csvData?.rowModel.rowsToDisplay : []
        records.forEach(x => {
            if(x.data)
            data.push(x.data);
        });
        let flag = 0;
        data = data.filter(x => x.IsSelected);
        data.map(x => {
            if (x.QuoteStatus !== 'In Review') {
                flag = 1
            }
        })
        if (!flag && data.length > 0)
            return false;
        else return true;
    }
    @action
    isApproveRoleDisabled = () => {
        let access = false;
        const currentUserRoles = this.accountStore.userRoles;
        if (currentUserRoles.includes('ptstruckingmanager') || currentUserRoles.includes('ptsdispatcher') || currentUserRoles.includes('ptstruckratereadonly')) {
            access = true;
        }
        return access;
    }

    @Loader
    @Catch(() => errorHandler('File could not be exported. Please try again later.'))
    @action
    async approveTruckRates() {
        let reqbody: any = []
        this.dataToBeExported.forEach(data => {
            reqbody.push({
                "QuoteID": data.QuoteID,
                "QuoteStatus": "Approved",
                "ModifiedBy": this.userID
            })
        })

        const result: any = await this._service.updateTruckApproval(reqbody);

        this.uiService.toastService.success('Data updated successfully.');

        this.loadViewModel();

    }

    @Loader
    @Catch(() => errorHandler('File could not be exported. Please try again later.'))
    @action
    async sendToRATruckRates() {
        let reqbody: any = 
        {
            "ModifiedBy": this.userID
        }
    

        const result: any = await this._service.sendToRA(reqbody);
        if(result.StatusCode === 422)
            this.uiService.toastService.error(result.Message);
        else
            this.uiService.toastService.success('Data updated successfully.');

        this.loadViewModel();

    }

    @action
    getButtonText = () => {
        const csvData: any = this.agGridService.getNodes();
        let data: any = [];
        const records = csvData?.rowModel.rowsToDisplay ? csvData?.rowModel.rowsToDisplay : []
        console.log(records);
        records.forEach(x => {
            if(x.data)
            data.push(x.data);
        });

        let count = 0;
        data.map(x => {
            if (x.IsSelected) {
                count++
            }
        })
        if (count === data.length) {
            this.selectButtonText = 'Deselect All'
        } else {
            this.selectButtonText = 'Select All'
        }

        return this.selectButtonText
    }


    @action
    handleSelectAllData = (data) => {
        let loadStatusArray: any = [];
        console.log(this.selectButtonText);
        if (this.selectButtonText === 'Select All') {

            this.dataToBeExported = [];
            data.map((item, index) => {
                if (!(item.IsOriginError || item.IsDestinationError) || item.QuoteStatus === 'Econ') {
                    item.IsSelected = true;
                    this.handleCheckboxClick(item, false, 'IsSelected')
                }
            });
            this.selectButtonText = 'Deselect All';
        } else {
            data.map((item, index) => {
                item.IsSelected = false;
                this.handleCheckboxClick(item, true, 'IsSelected')
            });
            this.dataToBeExported = [];
            this.selectButtonText = 'Select All';
        }
    };
}